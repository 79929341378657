.image-wrapper {
  display: flex; 
  flex-direction: column; 
  align-items: center;

  &--bottom {
    margin: 0 auto;
    border-radius: 0.55388rem 0.55388rem 0rem 0rem;
    border: 0.0625rem solid rgba(13, 128, 216, 0.50);
    border-bottom: 0;
  }
}

.layout .flex-container.stripe:has(.image-wrapper--bottom) {
	padding-bottom: 0;
}